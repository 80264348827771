import React from 'react';
import { Box, Button, Typography, Paper, Container, Grid, Zoom, Fade, List, ListItem, ListItemIcon, ListItemText, Divider, Stack } from '@mui/material';
import TimerIcon from '@mui/icons-material/Timer';
import StopIcon from '@mui/icons-material/Stop';
import PersonIcon from '@mui/icons-material/Person';
import SpeedIcon from '@mui/icons-material/Speed';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';


const ActiveGame = ({ timeLeft, isHost, finishGame, players, currentPlayerId, settings,roomData}) => {
    return (
        <Container maxWidth="sm" dir={'rtl'} sx={{ px: '0 !important' }}>
            <Fade in={true} timeout={800}>
                <Paper 
                    elevation={3} 
                    sx={{ 
                        p: 4, 
                        borderRadius: 2,
                        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Zoom in={true} timeout={500}>
                                <Typography 
                                    variant="h4" 
                                    align="center"
                                    color="white"
                                    sx={{
                                        fontWeight: 'bold',
                                        textShadow: '2px 2px 4px rgba(0,0,0,0.2)'
                                    }}
                                >
                                    بازی در حال انجام است!
                                </Typography>
                            </Zoom>
                        </Grid>

                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Stack spacing={1} alignItems="center">
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <MeetingRoomIcon sx={{ fontSize: 14, color: 'white' }} />
                                    <Typography color="white" sx={{fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0,0,0,0.2)'}}>
                                        {roomData.name}
                                    </Typography>
                                </Stack>

                                <Stack direction="row" spacing={1} alignItems="center">
                                    <SpeedIcon sx={{ fontSize: 14, color: 'white' }} />
                                    <Typography color="white" sx={{fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0,0,0,0.2)'}}>
                                        سطح: {roomData.settings.difficulty === 1 ? 'آسان' : roomData.settings.difficulty === 2 ? 'معمولی' : 'سخت'}
                                    </Typography>
                                </Stack>

                                <Stack direction="row" spacing={1} alignItems="center">
                                    <SupervisorAccountIcon sx={{ fontSize: 14, color: 'white' }} />
                                    <Typography color="white" sx={{fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0,0,0,0.2)'}}>
                                        جاسوس: {roomData.settings.spy_count}
                                    </Typography>
                                </Stack>

                            </Stack>
                            <Box 
                                sx={{ 
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 2,
                                    p: 3,
                                    bgcolor: 'rgba(255,255,255,0.1)',
                                    borderRadius: 2
                                }}
                            >
                                <TimerIcon sx={{ fontSize: 40, color: 'white' }} />
                                <Typography 
                                    variant="h3" 
                                    color="white"
                                    sx={{ 
                                        fontFamily: 'monospace',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper 
                                elevation={2}
                                sx={{
                                    bgcolor: 'rgba(255,255,255,0.1)',
                                    borderRadius: 2,
                                    p: 2
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    color="white"
                                    sx={{ mb: 2, fontWeight: 'bold' , textAlign: 'left' }}
                                >
                                    بازیکنان
                                </Typography>
                                <List>
                                    {players.map((player, index) => (
                                        <React.Fragment key={player.id}>
                                            <ListItem
                                                sx={{
                                                    bgcolor: player.id === currentPlayerId ? 'rgba(255,255,255,0.2)' : 'transparent',
                                                    borderRadius: 1
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <PersonIcon sx={{ color: 'white' }} />
                                                </ListItemIcon>
                                                <ListItemText 
                                                    primary={
                                                        <Typography color="white">
                                                            {player.player_name}
                                                            {player.id === roomData.owner_id && ' (میزبان)'}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            {index < players.length - 1 && (
                                                <Divider sx={{ bgcolor: 'rgba(255,255,255,0.1)' }} />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </List>
                            </Paper>
                        </Grid>

                        {isHost && (
                            <Grid item xs={12}>
                                <Zoom in={true} timeout={700}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={finishGame}
                                        fullWidth
                                        size="large"
                                        startIcon={<StopIcon />}
                                        sx={{ 
                                            py: 2,
                                            borderRadius: 2,
                                            fontWeight: 'bold',
                                            boxShadow: 3,
                                            transition: 'transform 0.2s ease-in-out',
                                            '&:hover': {
                                                transform: 'scale(1.02)'
                                            }
                                        }}
                                    >
                                        پایان بازی
                                    </Button>
                                </Zoom>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Fade>
        </Container>
    );
};

export default ActiveGame;