import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';

const Help = () => {
    return (
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                    🕵️‍♂️ راهنمای ماموریت مخفی 🕵️‍♀️
                </Typography>
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h5" gutterBottom sx={{ color: 'secondary.main' }}>
                        📖 داستان ماموریت:
                    </Typography>
                    <Typography paragraph>
                        به دنیای هیجان‌انگیز جاسوسی خوش آمدید! شما یک کارآگاه زبده در سازمان اطلاعاتی هستید. خبرهای نگران‌کننده‌ای به گوش رسیده: یک نفوذی در میان ماست! حالا همه همکاران دور هم جمع شده‌اند تا با هوش و ذکاوت، این جاسوس ماهر را شناسایی کنند.
                    </Typography>

                    <Typography variant="h5" gutterBottom sx={{ color: 'secondary.main' }}>
                        🎮 پیش‌نیازهای شروع ماموریت:
                    </Typography>
                    <Typography component="ul">
                        <li>یک تیم 3 تا 12 نفره از کارآگاهان باهوش</li>
                        <li>حضور همه اعضای تیم در یک مکان امن</li>
                    </Typography>

                    <Typography variant="h5" gutterBottom sx={{ color: 'secondary.main' }}>
                        🎯 اهداف ماموریت:
                    </Typography>
                    <Typography component="ul">
                        <li><strong>ماموریت جاسوس:</strong> نفوذ هوشمندانه و کشف کلمه مخفی با تحلیل پاسخ‌های دیگران</li>
                        <li><strong>ماموریت کارآگاهان:</strong> شناسایی و دستگیری نفوذی(ها) پیش از آنکه دیر شود!</li>
                    </Typography>

                    <Typography variant="h5" gutterBottom sx={{ color: 'secondary.main' }}>
                        📋 مراحل عملیات:
                    </Typography>
                    <Typography component="ul">
                        <li><strong>⏱️ زمان عملیات:</strong> بین 3 تا 12 دقیقه طلایی (بسته به تعداد نیروهای درگیر)</li>
                        <li><strong>🎴 کلمه رمز:</strong> هر کارآگاه کارت مخصوص خود را دریافت می‌کند. همه کارآگاهان کلمه یکسانی می‌بینند، اما جاسوس کارتش خالیست!</li>
                        <li><strong>❓ بازجویی:</strong> نوبتی از یکدیگر سوال‌های زیرکانه بپرسید. مثلاً: "ترجیح می‌دهی شب عملیات کنی یا روز؟"</li>
                        <li><strong>💭 پاسخگویی:</strong> پاسخ‌ها باید هوشمندانه و بدون لو دادن مستقیم کلمه باشد</li>
                        <li><strong>⚠️ قانون طلایی:</strong> هرگز نمی‌توانید از کسی که از شما سوال پرسیده، بلافاصله سوال کنید!</li>
                    </Typography>

                    <Typography variant="h5" gutterBottom sx={{ color: 'secondary.main' }}>
                        🔍 عملیات شناسایی جاسوس:
                    </Typography>
                    <Typography component="ul">
                        <li><strong>🗳️ رای‌گیری:</strong> هر لحظه که به فردی مشکوک شدید، می‌توانید درخواست رای‌گیری کنید</li>
                        <li><strong>📊 شرایط موفقیت:</strong> برای دستگیری مظنون، تعداد رای‌ها باید برابر تعداد کارآگاهان پاک باشد. دقت کنید که هر کارآگاه فقط یک فرصت متهم کردن دارد!</li>
                        <li><strong>🎭 لحظه حقیقت:</strong> فرد متهم باید هویت واقعی خود را فاش کند</li>
                    </Typography>

                    <Typography variant="h5" gutterBottom sx={{ color: 'secondary.main' }}>
                        💡 فرصت طلایی جاسوس:
                    </Typography>
                    <Typography paragraph>
                        جاسوس می‌تواند در لحظه‌ای حساس، هویت خود را فاش کرده و کلمه رمز را حدس بزند. حدس درست می‌تواند پیروزی را برایش به ارمغان آورد!
                    </Typography>

                    <Typography variant="h5" gutterBottom sx={{ color: 'secondary.main' }}>
                        🏁 پایان عملیات:
                    </Typography>
                    <Typography component="ul">
                        <li><strong>👮‍♂️ دستگیری موفق:</strong> شناسایی و حذف آخرین جاسوس یا کارآگاه</li>
                        <li><strong>🎯 حدس موفق:</strong> کشف کلمه رمز توسط جاسوس</li>
                        <li><strong>⌛ اتمام زمان:</strong> پایان مهلت عملیات</li>
                    </Typography>

                    <Typography variant="h5" gutterBottom sx={{ color: 'secondary.main' }}>
                        🏆 امتیازات و پاداش‌ها:
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        🕵️‍♂️ پیروزی تیم جاسوس:
                    </Typography>
                    <Typography component="ul">
                        <li>✨ <strong>2 امتیاز:</strong> عملیات بدون دستگیری</li>
                        <li>✨ <strong>4 امتیاز:</strong> موفقیت در گمراه کردن و حذف یک کارآگاه</li>
                        <li>✨ <strong>4 امتیاز:</strong> کشف موفقیت‌آمیز کلمه رمز</li>
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                        👮‍♂️ پیروزی تیم کارآگاهان:
                    </Typography>
                    <Typography component="ul">
                        <li>🌟 <strong>1 امتیاز:</strong> پاداش پایه هر کارآگاه</li>
                        <li>🌟 <strong>2 امتیاز ویژه:</strong> برای کارآگاهان تیزهوشی که در شناسایی جاسوس نقش کلیدی داشتند</li>
                    </Typography>

                    <Typography variant="h5" gutterBottom sx={{ color: 'secondary.main' }}>
                        🎮 تعداد دورهای بازی:
                    </Typography>
                    <Typography paragraph>
                        ماموریت‌ها می‌تواند به دلخواه شما ادامه پیدا کند. در نهایت، کارآگاه یا جاسوسی که بیشترین امتیاز را کسب کند، به‌عنوان برترین مامور شناخته خواهد شد! 🏆
                    </Typography>
                </Box>
            </Paper>
        </Container>
    );
};

export default Help; 