import React, {useEffect, useState} from 'react';
import useWebSocket from './hooks/useWebSocket';
import {Box, Typography, useMediaQuery, useTheme, Zoom} from '@mui/material';

import {useDispatch} from "react-redux";
import {word, wordHard, wordNormal} from "./api/Words";
import LoginForm from './components/LoginForm';
import GameSettings from './components/GameSettings';
import GamePreparation from './components/GamePreparation';
import ActiveGame from './components/ActiveGame';
import SecurityIcon from '@mui/icons-material/Security';
import RoomChatBox from './components/RoomChatBox';
import GlobalChatBox from './components/GlobalChatBox';
import EnterNameForm from './components/EnterNameForm';
import useGameLogic from './hooks/useGameLogic';
import useRoomManagement from './hooks/useRoomManagement';
import useChatManagement from './hooks/useChatManagement';
import GameLayout from './layout/GameLayout';


const WordGuessNew = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [roomId, setRoomId] = useState(0);
    const [playerName, setPlayerName] = useState(localStorage.getItem('playerName') || '');
    const [players, setPlayers] = useState([]);
    const [isHost, setIsHost] = useState(false);
    const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
    const [showCurrentWord, setShowCurrentWord] = useState(false);
    const [gameStarted, setGameStarted] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const [gameSettingsVisible, setGameSettingsVisible] = useState(false);
    const [gameDifficulty, setGameDifficulty] = useState(3);
    const [joinedToRoom, setJoinedToRoom] = useState(false);
    const [activeRooms, setActiveRooms] = useState([]);
    const [roomData, setRoomData] = useState(null);
    const [roomError, setRoomError] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [globalChatMessages, setGlobalChatMessages] = useState([]);
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();
    const [hasEnteredName, setHasEnteredName] = useState(false);

    const handleNameSubmit = (e) => {
        e.preventDefault();
        if (playerName.trim()) {
            // send message to server to set player name
            sendMessage({event: 'player-register', playerName: playerName});
            setHasEnteredName(true);
        }
    };

    useEffect(() => {
        if (playerName.trim() !== '') {
            localStorage.setItem('playerName', playerName);
        }
    }, [playerName]);

    const [settings, setSettings] = useState({
        player_count: 3,
        spy_count: 1,
        difficulty: gameDifficulty,
        words: gameDifficulty === 1 ? word : gameDifficulty === 2 ? wordNormal : wordHard,
        match_time: 5
    });

    useEffect(() => {
        let newSettings = {...settings};
        newSettings.words = gameDifficulty === 1 ? word : gameDifficulty === 2 ? wordNormal : wordHard;
        setSettings(newSettings);
        updateSettings('difficulty', gameDifficulty, newSettings, isHost);
    }, [gameDifficulty]);


    const {isConnected, sendMessage, ws, decryptMessage} = useWebSocket();
    const [currentPlayerId, setCurrentPlayerId] = useState(0);
    const {
        newGame,
        startNewGame,
        handleShowWord,
        handleUnderstand,
        startTimer,
        finishGame,
        handleGameStarted
    } = useGameLogic(sendMessage, settings, players);

    const {
        createRoom,
        joinRoom,
        removeRoom,
        leaveRoom,
        updateSettings
    } = useRoomManagement(sendMessage);

    const {
        sendChatMessage,
        sendGlobalChatMessage,
        getLastGlobalChatMessage
    } = useChatManagement(sendMessage, ws);


    useEffect(() => {
        if (!ws) return;

        const messageHandler = (event) => {
            let data = event.data;
            let roomSettings = null;
            data = decryptMessage(event.data);
            data = JSON.parse(data);
            console.log('event data', data.event, players);
            switch (data.event) {
                case 'active-rooms':
                    setActiveRooms(data.rooms);
                    break;
                case 'player-registered':
                    let $auth = data.auth;
                    if ($auth) {
                        localStorage.setItem('auth', $auth);
                    }
                    break;
                case 'client-id':
                    setCurrentPlayerId(data.clientId);
                    console.log('currentPlayerId', currentPlayerId);
                    break;
                case 'auth-error':
                    setCurrentPlayerId(0);
                    setPlayerName('');
                    setHasEnteredName(false);
                    localStorage.removeItem('auth');
                    break;
                case 'room-players':
                    setPlayers(data.players);
                    break;
                case 'player-joined':
                    setPlayers(data.players);
                    if (data.roomData.owner_id === currentPlayerId) {
                        setIsHost(true);
                    }
                    roomSettings = {...data.roomData}.settings;
                    roomSettings = JSON.parse(roomSettings);
                    setRoomData({...data.roomData, settings: roomSettings});
                    setRoomError('');
                    break;

                case 'player-disconnected':
                    setPlayers(prev => prev.filter(player => player.id !== data.playerId));
                    break;

                case 'room-created':
                    setRoomId(data.roomId);

                    roomSettings = {...data.roomData}.settings;
                    roomSettings = JSON.parse(roomSettings);
                    setRoomData({...data.roomData, settings: roomSettings});

                    setPlayers([{player_name: playerName, id: currentPlayerId}]);
                    setIsHost(true);
                    setGameSettingsVisible(true);
                    setJoinedToRoom(true);
                    setRoomError('');
                    break;

                case 'room-removed':
                    setRoomId('');
                    setGameStarted(false);
                    setJoinedToRoom(false);
                    setGameSettingsVisible(false);
                    setPlayers([]);
                    setIsHost(false);
                    setCurrentPlayerIndex(0);
                    setShowCurrentWord(false);
                    setTimeLeft(0);
                    setActiveRooms(data.rooms);
                    alert('اتاق حذف شد');
                    break;

                case 'room-grabbed ':
                    setRoomId(data.roomData?.id);
                    setPlayers([{player_name: playerName, id: currentPlayerId}]);
                    setIsHost(true);
                    setGameSettingsVisible(true);
                    setJoinedToRoom(true);
                    setRoomError('');
                    break;

                case 'room-joined':

                    setGameStarted(false);
                    setGameSettingsVisible(true);
                    setTimeLeft(0);
                    break;


                case 'settings-updated':
                    roomSettings = {...data.roomData}.settings;
                    roomSettings = JSON.parse(roomSettings);
                    setRoomData({...data.roomData, settings: roomSettings});

                    setSettings(data.settings);
                    break;

                case 'game-started':
                    handleGameStarted(data.gameData);
                    setCurrentPlayerIndex(0);
                    setShowCurrentWord(false);
                    setGameStarted(false);
                    setGameSettingsVisible(false);
                    break;

                case 'word-reveal':
                    if (players[data.playerIndex]?.id === currentPlayerId) {
                        setShowCurrentWord(true);
                    }
                    break;

                case 'player-turn':
                    console.log('player turn', data);
                    setCurrentPlayerIndex(data.nextIndex);
                    break;

                case 'game-phase-started':
                    setGameStarted(true);
                    setTimeLeft(settings.match_time * 60);
                    startTimer(settings.match_time * 60, (timeLeft) => setTimeLeft(timeLeft));
                    break;

                case 'game-over':
                    console.log('game over');
                    setGameStarted(false);
                    setGameSettingsVisible(true);
                    setCurrentPlayerIndex(0);
                    setShowCurrentWord(false);
                    setTimeLeft(0);
                    break;

                case 'room-chat-message':
                    setChatMessages(prev => [...prev, data.message]);
                    break;

                case 'global-chat-message':
                    setGlobalChatMessages(prev => [...prev, data.message]);
                    break;

                case 'last-global-chat-message':
                    setGlobalChatMessages([...data.messages]);
                    break;
            }
        };

        ws.addEventListener('message', messageHandler);

        return () => {
            ws.removeEventListener('message', messageHandler);
        };
    }, [ws, roomData, playerName, settings, activeRooms, isHost, gameSettingsVisible, gameStarted, timeLeft, currentPlayerIndex, showCurrentWord, newGame]);


    if (currentPlayerId)
        console.log(currentPlayerIndex, players[currentPlayerIndex]?.id, currentPlayerId);
    useEffect(() => {
        getLastGlobalChatMessage();
    }, [getLastGlobalChatMessage]);

    const auth = localStorage.getItem('auth');
    useEffect(() => {
        console.log('hasEnteredName', hasEnteredName, auth, ws);
        if (ws?.readyState === WebSocket.OPEN) {
            if (hasEnteredName || auth != '') {
                console.log('sending client-auth');
                setTimeout(() => sendMessage({event: 'client-auth'}), 2000);
            }
        }
    }, [ws?.readyState, hasEnteredName, auth]);

    useEffect(() => {
        if (globalChatMessages?.length < 1)
            setTimeout(() => sendMessage({event: 'last-global-chat-message'}), 2000)
    }, []);
    return (
        <GameLayout>
            {(!hasEnteredName && !auth) ? (
                <EnterNameForm
                    playerName={playerName}
                    setPlayerName={setPlayerName}
                    handleNameSubmit={handleNameSubmit}
                />
            ) : (
                <>

                    <Zoom in={true} timeout={800}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',

                                mb: 4
                            }}
                        >
                            <SecurityIcon
                                sx={{
                                    fontSize: {xs: 60, sm: 80},
                                    color: 'primary.main',
                                    mb: 2
                                }}
                            />
                            <Typography
                                variant={isMobile ? "h5" : "h4"}
                                component="h1"
                                sx={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    color: 'primary.main',
                                    textShadow: '1px 1px 2px rgba(0,0,0,0.1)'
                                }}
                            >
                                بازی کلمه جاسوس
                            </Typography>
                        </Box>
                    </Zoom>

                    {/* Login Form */}
                    {(!roomId || !joinedToRoom) && (
                        <>
                            <LoginForm
                                roomId={roomId}
                                setRoomId={setRoomId}
                                playerName={playerName}
                                setPlayerName={setPlayerName}
                                activeRooms={activeRooms}
                                roomError={roomError}
                                createRoom={createRoom}
                                settings={settings}
                                joinRoom={() => {
                                    joinRoom(roomId, playerName);
                                    setRoomError('');
                                    setJoinedToRoom(true);
                                }}
                            />
                            <GlobalChatBox
                                currentPlayerId={currentPlayerId}
                                players={players}
                                sendGlobalChatMessage={sendGlobalChatMessage}
                                globalChatMessages={globalChatMessages}
                            />
                        </>

                    )}

                    {/* Game Settings */}
                    {gameSettingsVisible && !gameStarted && roomId && (
                        <>
                            <GameSettings
                                players={players}
                                isHost={isHost}
                                gameDifficulty={gameDifficulty}
                                setGameDifficulty={setGameDifficulty}
                                settings={settings}
                                updateSettings={updateSettings}
                                startNewGame={() => {
                                    startNewGame(isHost);
                                    setCurrentPlayerIndex(0);
                                    setShowCurrentWord(false);
                                    setGameStarted(false);
                                    setGameSettingsVisible(false);
                                }}
                                removeRoom={() => removeRoom(roomId, isHost)}
                                currentPlayerId={currentPlayerId}
                                leaveRoom={() => {
                                    leaveRoom(roomId);
                                    setRoomId(0);
                                    setJoinedToRoom(false);
                                    setGameSettingsVisible(false);
                                    setPlayers([]);
                                    setIsHost(false);
                                    setGameStarted(false);
                                    setTimeLeft(0);
                                }}
                                roomData={roomData}
                            />
                            <RoomChatBox
                                currentPlayerId={currentPlayerId}
                                players={players}
                                roomId={roomId}
                                sendRoomChatMessage={sendChatMessage}
                                chatMessages={chatMessages}
                                setMessage={setMessage}
                                message={message}
                            />
                        </>
                    )}

                    {/* Game Preparation */}
                    {!gameSettingsVisible && !gameStarted && newGame.words?.length > 0 && joinedToRoom && (
                        <>
                            <GamePreparation
                                players={players}
                                currentPlayerIndex={currentPlayerIndex}
                                showCurrentWord={showCurrentWord}
                                handleShowWord={() => handleShowWord(currentPlayerIndex, currentPlayerId)}
                                handleUnderstand={() => setCurrentPlayerIndex(handleUnderstand(currentPlayerIndex, players))}
                                newGame={newGame}
                                currentPlayerId={currentPlayerId}
                                roomData={roomData}
                            />
                            <RoomChatBox
                                currentPlayerId={currentPlayerId}
                                players={players}
                                roomId={roomId}
                                sendRoomChatMessage={sendChatMessage}
                                chatMessages={chatMessages}
                                setMessage={setMessage}
                                message={message}
                            />
                        </>
                    )}

                    {/* Active Game */}
                    {gameStarted && (
                        <>
                            <ActiveGame
                                timeLeft={timeLeft}
                                isHost={isHost}
                                finishGame={() => {
                                    finishGame(roomId, playerName, message);
                                    setGameStarted(false);
                                    setGameSettingsVisible(true);
                                    setTimeLeft(0);
                                }}
                                players={players}
                                currentPlayerId={currentPlayerId}
                                roomData={roomData}
                                settings={settings}
                            />
                            <RoomChatBox
                                currentPlayerId={currentPlayerId}
                                players={players}
                                roomId={roomId}
                                sendRoomChatMessage={sendChatMessage}
                                chatMessages={chatMessages}
                                setMessage={setMessage}
                                message={message}
                            />
                        </>
                    )}

                </>
            )}
        </GameLayout>
    );
};

export default WordGuessNew;