import React from 'react';
import { AppBar, Toolbar, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import HelpIcon from '@mui/icons-material/Help';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const NavigationBar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <AppBar position="static" sx={{ mb: 2 }}>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        <Link
                            to="/"
                            color="inherit"
                            sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 1,
                                textDecoration: 'none',
                                color: 'white'
                            }}
                        >
                            <HomeIcon sx={{ color: 'white' }} />
                            <Typography sx={{ display: { xs: 'none', sm: 'block' }, color: 'white' }}>
                                خانه
                            </Typography>
                        </Link>
                        <Link
                            to="/help"
                            color="inherit"
                            sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 1,
                                textDecoration: 'none',
                                color: 'white'
                            }}
                        >
                            <HelpIcon sx={{ color: 'white' }} />
                            <Typography sx={{ display: { xs: 'none', sm: 'block' }, color: 'white' }}>
                                راهنما
                            </Typography>
                        </Link>
                        <Link
                            to="/contact"
                            color="inherit"
                            sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 1,
                                textDecoration: 'none',
                                color: 'white'
                            }}
                        >
                            <ContactMailIcon sx={{ color: 'white' }} />
                            <Typography sx={{ display: { xs: 'none', sm: 'block' }, color: 'white' }}>
                                تماس با ما
                            </Typography>
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
    );
};

export default NavigationBar;
