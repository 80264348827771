import { useState, useCallback } from 'react';

export const useGameLogic = (sendMessage, settings, players) => {
    const [newGame, setNewGame] = useState({
        words: [],
        player_index: 0,
        show_words: false,
    });

    const startNewGame = useCallback((isHost) => {
        if (!isHost) return;

        console.log('startNewGame', isHost, players, settings);
        // Generate spy indexes
        let spyIndexes = [];
        for (let i = 0; i < settings.spy_count; i++) {
            let randIndex = Math.floor(Math.random() * players?.length);
            spyIndexes.push(randIndex);
        }

        const randomWord = settings.words[Math.floor(Math.random() * settings.words?.length)];
        const newWords = Array(players?.length).fill('').map((_, index) =>
            spyIndexes.includes(index) ? 'شما جاسوس هستید!' : randomWord
        );

        sendMessage({
            event: 'start-game',
            gameData: {
                words: newWords,
                spyIndexes,
                settings
            }
        });

        setNewGame({
            words: newWords,
            player_index: 0,
            show_words: false
        });

        sendMessage({
            event: 'next-player',
            nextIndex: 0
        });
    }, [players, settings, sendMessage]);

    const handleShowWord = useCallback((currentPlayerIndex, currentPlayerId) => {
        if(players[currentPlayerIndex]?.id !== currentPlayerId){
            console.log('نوبت شما نیست', currentPlayerIndex, currentPlayerId);
            return false;
        }
        sendMessage({
            event: 'request-word',
            playerIndex: currentPlayerIndex
        });
        return true;
    }, [players, sendMessage]);

    const handleUnderstand = useCallback((currentPlayerIndex, players) => {
        if (currentPlayerIndex < players?.length - 1) {
            const nextIndex = currentPlayerIndex + 1;
            sendMessage({
                event: 'next-player',
                nextIndex: nextIndex
            });
            return nextIndex;
        } else {
            sendMessage({
                event: 'game-phase-started'
            });
            return currentPlayerIndex;
        }
    }, [sendMessage]);

    const startTimer = useCallback((initialTime, onTick) => {
        const timer = setInterval(() => {
            onTick(prev => {
                if (prev <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const finishGame = useCallback((roomId) => {
        sendMessage({
            event: 'game-ended',
            roomId
        });
    }, [sendMessage]);

    const handleGameStarted = useCallback((gameData) => {
        setNewGame({
            words: gameData?.words,
            player_index: 0,
            show_words: false
        });
    }, []);

    return {
        newGame,
        startNewGame,
        handleShowWord,
        handleUnderstand,
        startTimer,
        finishGame,
        handleGameStarted
    };
}; 

export default useGameLogic;