import React from 'react';
import { Box, Container, Fade, Paper } from '@mui/material';

const GameLayout = ({ children }) => {
    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Fade in={true} timeout={1000}>
                <Box 
                    sx={{
                        minHeight: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2
                    }}
                >
                    <Paper 
                        elevation={4}
                        sx={{
                            p: { xs: 2, sm: 4 },
                            borderRadius: 3,
                            width: '100%',
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: 900,
                            background: 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)'
                        }}
                    >
                {children}
           </Paper>
           </Box>
           </Fade>
           </Container>
    );
};

export default GameLayout;