import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Paper, Fade, Zoom, useTheme, useMediaQuery } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const EnterNameForm = ({ playerName, setPlayerName, handleNameSubmit }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [focused, setFocused] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleNameSubmit();
    }
    
    return (
        <Fade in={true} timeout={1000}>
            <Paper
                elevation={3}
                sx={{
                    p: { xs: 3, sm: 4 },
                    borderRadius: 4,
                    background: 'linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)',
                    maxWidth: 250,
                    width: '100%',
                    margin: '0 auto'
                }}
            >
                <Box 
                    component="form" 
                    onSubmit={handleNameSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 3
                    }}
                >
                    <Zoom in={true} timeout={800}>
                        <Box sx={{ textAlign: 'center', mb: 2 }}>
                            <PersonIcon 
                                sx={{ 
                                    fontSize: { xs: 50, sm: 60 },
                                    color: 'primary.main',
                                    mb: 2
                                }}
                            />
                            <Typography
                                variant={isMobile ? 'h5' : 'h4'}
                                component="h1"
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'primary.main',
                                    textShadow: '1px 1px 2px rgba(0,0,0,0.1)'
                                }}
                            >
                                خوش آمدید
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                sx={{ mt: 1 }}
                            >
                                لطفا نام خود را وارد کنید
                            </Typography>
                        </Box>
                    </Zoom>

                    <TextField
                        fullWidth
                        label="نام شما"
                        value={playerName}
                        onChange={(e) => setPlayerName(e.target.value)}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        required
                        variant="outlined"
                        sx={{
                            maxWidth: 400,
                            '& .MuiOutlinedInput-root': {
                                transition: 'transform 0.2s',
                                transform: focused ? 'scale(1.02)' : 'scale(1)',
                            }
                        }}
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        disabled={!playerName.trim()}
                        sx={{
                            mt: 2,
                            py: 1.5,
                            px: 4,
                            borderRadius: 2,
                            fontSize: '1.1rem',
                            textTransform: 'none',
                            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                            transition: 'transform 0.2s',
                            '&:hover': {
                                transform: 'translateY(-2px)'
                            }
                        }}
                    >
                        ورود به بازی
                    </Button>
                </Box>
            </Paper>
        </Fade>
    );
};

export default EnterNameForm;