export const word = [
    'اسب', 'ماشین', 'معبد', 'سبدمیوه', 'فروشگاه', 'قطار', 'دریا', 'جهنم', 'کوه دماوند', 'شهربازی', 'گرگ', 'خورشید', 'باغ وحش', 'زمین فوتبال', 'داروخانه', 'ساحل', 'سیرک', 'پلیس', 'دفتر کار', 'آزمایشگاه'
];

export const wordNormal = [
    'نمایشگاه', 'دانشگاه', 'مرکز خرید', 'مترو', 'ایستگاه قطار', 'فرودگاه', 'کافه', 'رستوران', 'ورزشگاه', 'استخر', 'موزه', 'کتابخانه', 'سینما', 'پارک', 'کارخانه', 'اداره پست', 'آتش‌نشانی', 'باغ وحش', 'پارک آبی', 'ایستگاه پلیس', 'کلیسا', 'کشتی دزدان دریایی', 'جزیره متروکه'
];

export const wordHard = [
    'پالایشگاه نفت', 'معدن زغال‌سنگ', 'برج مراقبت فرودگاه', 'پشت صحنه تئاتر', 'استودیو ضبط موسیقی', 'کارخانه تولید شکلات', 'نیروگاه هسته‌ای', 'کارگاه نجاری', 'آزمایشگاه تشخیص پزشکی', 'آرامگاه باستانی', 'کاروانسرا', 'برج کج پیزا', 'معبد بودایی', 'میدان نقش جهان',
    ' شهر زیرزمینی', 'جنگل اسرارآمیز', 'زیردریایی نظامی', 'مقر مخفی سازمان جاسوسی', 'پشت صحنه برنامه تلویزیونی', 'سالن تشریفات کاخ ریاست جمهوری'
]

export default word;