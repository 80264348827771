import React from 'react';
import { Container, Typography, Paper, TextField, Button, Box, Fade, Zoom } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const Contact = () => {
    const handleSubmit = (event) => {
        event.preventDefault();
        // Add your form submission logic here
    };

    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Fade in={true} timeout={1000}>
                <Paper 
                    elevation={6} 
                    sx={{ 
                        p: { xs: 3, md: 6 }, 
                        mt: 4,
                        borderRadius: 4,
                        background: 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)',
                        boxShadow: '0 8px 32px rgba(0,0,0,0.1)'
                    }}
                >
                    <Zoom in={true} timeout={800}>
                        <Box sx={{ textAlign: 'center', mb: 4 }}>
                            <EmailIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
                            <Typography 
                                variant="h3" 
                                component="h1" 
                                sx={{
                                    fontWeight: 'bold',
                                    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent'
                                }}
                            >
                                تماس با ما
                            </Typography>
                        </Box>
                    </Zoom>

                    <Box 
                        component="form" 
                        onSubmit={handleSubmit} 
                        sx={{ 
                            mt: 3,
                            '& .MuiTextField-root': {
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'translateY(-2px)'
                                }
                            }
                        }}
                    >
                        <TextField
                            fullWidth
                            label="نام"
                            margin="normal"
                            required
                            variant="outlined"
                            sx={{ 
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            label="ایمیل"
                            type="email"
                            margin="normal"
                            required
                            variant="outlined"
                            sx={{ 
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            label="پیام"
                            multiline
                            rows={6}
                            margin="normal"
                            required
                            variant="outlined"
                            sx={{ 
                                mb: 4,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2
                                }
                            }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ 
                                mt: 2,
                                py: 1.5,
                                borderRadius: 2,
                                fontSize: '1.1rem',
                                fontWeight: 'bold',
                                background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                                boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
                                }
                            }}
                            fullWidth
                        >
                            ارسال پیام
                        </Button>
                    </Box>
                </Paper>
            </Fade>
        </Container>
    );
};

export default Contact;