import { useCallback } from 'react';

export const useRoomManagement = (sendMessage) => {
    const createRoom = useCallback((roomName, playerName, settings) => {
        if (!playerName.trim()) {
            alert('لطفا نام خود را وارد کنید');
            return;
        }

        const roomId = roomName ? roomName : playerName.trim().length > 3 ? playerName.trim() : Array.from({ length: 6 }, () =>
            String.fromCharCode(65 + Math.floor(Math.random() * 26))
        ).join('');

        sendMessage({
            event: 'create-room',
            roomId,
            playerName,
            settings
        });
    }, [sendMessage]);

    const joinRoom = useCallback((roomId, playerName) => {
        if (!playerName.trim()) {
            alert('لطفا نام خود را وارد کنید');
            return;
        }
        
        sendMessage({
            event: 'join-room',
            roomId,
            playerName
        });
    }, [sendMessage]);

    const removeRoom = useCallback((roomId, isHost) => {
        if (!isHost || !roomId) return;

        sendMessage({
            event: 'remove-room',
            roomId
        });
    }, [sendMessage]);

    const leaveRoom = useCallback((roomId) => {
        sendMessage({
            event: 'leave-room',
            roomId
        });
    }, [sendMessage]);

    const updateSettings = useCallback((field, value, settings, isHost) => {
        if (!isHost) return;

        const newSettings = {
            ...settings,
            [field]: value
        };

        sendMessage({
            event: 'update-settings',
            settings: newSettings
        });

        return newSettings;
    }, [sendMessage]);

    return {
        createRoom,
        joinRoom,
        removeRoom,
        leaveRoom,
        updateSettings
    };
}; 

export default useRoomManagement;