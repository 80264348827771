import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import WordGuessNew from './WordGuessNew';
import Help from './components/Help';
import Contact from './components/Contact';
import './App.css';

const App = () => {
    return (   
        <Router>
            <NavigationBar />
            <Routes>
                <Route path="/" element={<WordGuessNew />} />
                <Route path="/help" element={<Help />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </Router>
    );
};

export default App;
