import { useState, useEffect, useCallback, useRef } from 'react';
import CryptoJS from 'crypto-js';

const useWebSocket = () => {
    const [isConnected, setIsConnected] = useState(false);
    const wsRef = useRef(null);
    const [clientId, setClientId] = useState(null);
    const heartbeatIntervalRef = useRef(null);
    const lastHeartbeatRef = useRef(null);
    const HEARTBEAT_INTERVAL = 30000; // 30 seconds
    const secretKey = CryptoJS.enc.Utf8.parse('01JN0XSNPXDK9ZVD07QNGZV3YHN0XSNP');
    const iv = CryptoJS.enc.Utf8.parse('cm7lt1xzq00003m8');

    function encryptMessage(message) {
        return message;
        const encrypted = CryptoJS.AES.encrypt(message, secretKey, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return CryptoJS.enc.Base64.stringify(encrypted.ciphertext); // هماهنگ با PHP
    }
    
    function decryptMessage(encryptedMessage) {
        return encryptedMessage;
        const ciphertext = CryptoJS.enc.Base64.parse(encryptedMessage); // تبدیل از Base64
        const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, secretKey, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    const sendMessage = useCallback((data) => {
        if (wsRef.current?.readyState === WebSocket.OPEN) {
            let $auth = localStorage.getItem('auth');
            if($auth){
                data.auth = $auth;
            }
            wsRef.current.send(encryptMessage(JSON.stringify(data)));
        }
    }, []);

    const startHeartbeat = useCallback(() => {
        if (heartbeatIntervalRef.current) {
            clearInterval(heartbeatIntervalRef.current);
        }

        heartbeatIntervalRef.current = setInterval(() => {
            const now = Date.now();
            // Only send heartbeat if more than HEARTBEAT_INTERVAL has passed
            if (!lastHeartbeatRef.current || (now - lastHeartbeatRef.current) >= HEARTBEAT_INTERVAL) {
                sendMessage({ event: 'heartbeat' });
                lastHeartbeatRef.current = now;
            }
        }, HEARTBEAT_INTERVAL);
    }, [sendMessage]);

    const stopHeartbeat = useCallback(() => {
        if (heartbeatIntervalRef.current) {
            clearInterval(heartbeatIntervalRef.current);
            heartbeatIntervalRef.current = null;
        }
        lastHeartbeatRef.current = null;
    }, []);

    const handleMessage = useCallback((event) => {
        try {
            let data = decryptMessage(event.data);
            data = JSON.parse(data);

            switch (data.event) {
                case 'client-data':
                    setClientId(data.clientId);
                    console.log('clientId', data.clientId);
                    break;
                case 'ping':
                    sendMessage({ event: 'pong' });
                    break;
                case 'pong':
                    // Update last heartbeat time
                    lastHeartbeatRef.current = Date.now();
                    break;
                // Don't handle heartbeat responses here
                default:
                    // Handle other messages in the component
                    break;
            }
        } catch (error) {
            console.error('Error parsing message:', error);
        }
    }, [sendMessage]);

    const connect = useCallback(() => {
        try {
            if (wsRef.current?.readyState === WebSocket.OPEN) {
                return; // Already connected
            }

            wsRef.current = new WebSocket('https://spy-api.garavand.ir/ws');

            wsRef.current.onopen = () => {
                console.log('WebSocket Connected');
                setIsConnected(true);
                lastHeartbeatRef.current = Date.now();
                startHeartbeat();
            };

            wsRef.current.onclose = () => {
                console.log('WebSocket Disconnected');
                setIsConnected(false);
                stopHeartbeat();
            };

            wsRef.current.onerror = (error) => {
                console.error('WebSocket Error:', error);
                stopHeartbeat();
            };

            wsRef.current.onmessage = handleMessage;

        } catch (error) {
            console.error('WebSocket Connection Error:', error);
            stopHeartbeat();
        }
    }, [startHeartbeat, stopHeartbeat, handleMessage]);

    useEffect(() => {
        connect();

        return () => {
            stopHeartbeat();
            if (wsRef.current) {
                wsRef.current.close();
                wsRef.current = null;
            }
        };
    }, [connect, stopHeartbeat]);

    return {
        isConnected,
        sendMessage,
        ws: wsRef.current,
        decryptMessage,
        clientId   
    };
};

export default useWebSocket;