import React from 'react';
import { Box, Button, Typography, Paper, Grid, Divider, Container } from '@mui/material';

const GamePreparation = ({
    players,
    currentPlayerIndex,
    showCurrentWord,
    handleShowWord,
    handleUnderstand,
    newGame,
    currentPlayerId,
    roomData
}) => {
    return (
        <Container maxWidth="sm" dir={'rtl'} sx={{ px: '0 !important' }}>
            <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                            <Typography variant="h4" gutterBottom color="primary">
                                اتاق {roomData?.name}
                            </Typography>
                            <Divider sx={{ width: '100%', my: 2 }} />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="h6" gutterBottom color="text.secondary">
                                بازیکنان حاضر ({players?.length})
                            </Typography>
                            <Box sx={{ 
                                display: 'flex', 
                                flexDirection: 'column',
                                gap: 1,
                                p: 2,
                                bgcolor: 'background.default',
                                borderRadius: 1
                            }}>
                                {players.map((player, index) => (
                                    <Typography 
                                        key={index} 
                                        sx={{
                                            p: 1,
                                            borderRadius: 1,
                                            bgcolor: player.id === currentPlayerId ? 'success.light' : 
                                                    index === currentPlayerIndex ? 'warning.light' : 'background.paper',
                                            fontWeight: (player.id === currentPlayerId || index === currentPlayerIndex) ? 'bold' : 'normal',
                                            color: player.id === currentPlayerId ? 'success.dark' : 
                                                  index === currentPlayerIndex ? 'warning.dark' : 'text.primary'
                                        }}
                                    >
                                        {player.player_name}
                                    </Typography>
                                ))}
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" align="center" color="primary" gutterBottom>
                            نوبت: {players[currentPlayerIndex]?.player_name ?? 'نامشخص'}
                        </Typography>
                        <Typography variant="subtitle1" align="center" color="text.secondary" gutterBottom>
                            در حال انتظار بازیگران دیگر
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        {!showCurrentWord ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleShowWord}
                                fullWidth
                                size="large"
                                disabled={players[currentPlayerIndex]?.id !== currentPlayerId}
                                sx={{ 
                                    py: 1.5,
                                    borderRadius: 2,
                                    boxShadow: 3
                                }}
                            >
                                نمایش کلمه من
                            </Button>
                        ) : (
                            <Box sx={{ textAlign: 'center' }}>
                               {players[currentPlayerIndex]?.id === currentPlayerId && (
                                <Paper 
                                    elevation={2} 
                                    sx={{ 
                                        p: 3, 
                                        mb: 2,
                                        bgcolor: 'primary.light',
                                        borderRadius: 2
                                    }}
                                >
                                    <Typography variant="h4" color="primary.contrastText">
                                        {newGame.words[currentPlayerIndex]}
                                    </Typography>
                                </Paper>)
                                }

                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={handleUnderstand}
                                    fullWidth
                                    size="large"
                                    disabled={players[currentPlayerIndex]?.id !== currentPlayerId}
                                    sx={{ 
                                        py: 1.5,
                                        borderRadius: 2,
                                        boxShadow: 3
                                    }}
                                >
                                    متوجه شدم
                                </Button>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default GamePreparation;