import React from 'react';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Paper,
    Grid,
    Container,
    Divider,
    Card,
    CardContent,
    useTheme,
    useMediaQuery,
    Zoom,
    Fade,
    IconButton,
    Tooltip,
    Slider,
    Stack
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import TimerIcon from '@mui/icons-material/Timer';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SpeedIcon from '@mui/icons-material/Speed';

const GameSettings = ({
    players,
    isHost,
    gameDifficulty,
    setGameDifficulty,
    settings,
    updateSettings,
    startNewGame,
    removeRoom,
    currentPlayerId,
    leaveRoom,
    roomData,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleTimeChange = (event, settings, isHost) => {
        updateSettings('match_time', parseInt(event.target.value), settings, isHost );
    };

    const marks = [
        { value: 3, label: '3' },
        { value: 5, label: '5' },
        { value: 10, label: '10' },
        { value: 15, label: '15' },
        { value: 20, label: '20' },
        { value: 25, label: '25' },
        { value: 30, label: '30' },
        { value: 45, label: '45' },
        { value: 60, label: '60' },
    ];

    console.log('roomData', roomData);
    return (
        <Container maxWidth="lg" sx={{ direction: 'rtl', px: '0 !important' }}>
            <Fade in={true} timeout={800}>
                <Paper 
                    elevation={3} 
                    sx={{ 
                        p: { xs: 1.5, sm: 3, md: 4 }, 
                        borderRadius: { xs: 2, sm: 3 },
                        background: 'linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)'
                    }}
                >
                    <Grid container dir={'rtl'} spacing={{ xs: 2, sm: 3, md: 4 }}>
                       
                        <Grid item xs={12} md={6}>
                            <Card dir={'rtl'} 
                            elevation={4} 
                            sx={{ 
                                height: '100%',
                                borderRadius: { xs: 1.5, sm: 2 },
                                transition: 'transform 0.3s ease',
                                '&:hover': {
                                    transform: 'translateY(-5px)'
                                },
                            
                            }}>
                                <CardContent  sx={{ height: '100%', p: { xs: 1.5, sm: 2 },display: 'flex', 
                                justifyContent: 'space-between', 
                                alignItems: 'flex-start',
                        
                                 }}>
                                    <Stack spacing={1} alignItems="center">
                                        <Stack direction="row" gap={1} alignItems="center">
                                            <MeetingRoomIcon sx={{ fontSize: 14}} />
                                        <Typography sx={{fontWeight: 'bold', fontSize: 14,color: 'text.secondary'}}>
                                            {roomData?.name}
                                        </Typography>
                                    </Stack>

                                    <Stack direction="row" gap={1} alignItems="center">
                                        <SpeedIcon sx={{ fontSize: 14}} />
                                        <Typography sx={{fontWeight: 'bold', fontSize: 14,color: 'text.secondary'}}>
                                            سطح: {roomData?.settings?.difficulty === 1 ? 'آسان' : roomData?.settings?.difficulty === 2 ? 'معمولی' : 'سخت'}
                                        </Typography>
                                    </Stack>

                                    <Stack direction="row" gap={1} alignItems="center">
                                        <SupervisorAccountIcon sx={{ fontSize: 14}} />
                                        <Typography sx={{fontWeight: 'bold', fontSize: 14,color: 'text.secondary'}}>
                                            جاسوس: {roomData?.settings?.spy_count}
                                        </Typography>
                                    </Stack>

                                </Stack>
                                <Box 
                                    sx={{ 
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 2,
                                        p: 1,
                                        bgcolor: 'rgba(255,255,255,0.1)',
                                        borderRadius: 2
                                    }}
                                >
                                    <TimerIcon sx={{ fontSize: 24 }} />
                                    <Typography 
                                        sx={{ 
                                            fontFamily: 'monospace',
                                            fontWeight: 'bold',
                                            fontSize: 24,
                                            color: 'text.secondary'
                                        }}
                                    >
                                        {roomData?.settings?.match_time} دقیقه
                                    </Typography>
                                </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Zoom in={true} timeout={700}>
                                <Card 
                                    elevation={4} 
                                    sx={{ 
                                        height: '100%',
                                        borderRadius: { xs: 1.5, sm: 2 },
                                        transition: 'transform 0.3s ease',
                                        '&:hover': {
                                            transform: 'translateY(-5px)'
                                        }
                                    }}
                                >
                                    <CardContent sx={{ height: '100%', p: { xs: 1.5, sm: 2 } }}>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                            <GroupIcon color="primary" sx={{ mr: 1, fontSize: { xs: '1.5rem', sm: '2rem' } }} />
                                            <Typography variant={isMobile ? "subtitle1" : "h6"} color="primary">
                                                بازیکنان حاضر ({players?.length})
                                            </Typography>
                                        </Box>
                                        <Box sx={{ 
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: { xs: 1, sm: 1.5 },
                                            maxHeight: { xs: '250px', sm: '300px' },
                                            height: '100%',
                                            width: '100%',
                                            overflowX: 'hidden',
                                            overflowY: 'auto',
                                            pr: 1
                                        }}>
                                            {players.map((player, index) => (
                                                <Fade in={true} timeout={500 + (index * 100)} key={index}>
                                                    <Paper
                                                        elevation={2}
                                                        sx={{
                                                            p: { xs: 1.5, sm: 2 },
                                                            bgcolor: player.id === currentPlayerId ? 'primary.light' : 'background.paper',
                                                            color: player.id === currentPlayerId ? 'primary.contrastText' : 'text.primary',
                                                            borderRadius: { xs: 1.5, sm: 2 },
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            transition: 'all 0.3s ease-in-out',
                                                            '&:hover': {
                                                                transform: 'scale(1.02)'
                                                            }
                                                        }}
                                                    >
                                                        <PersonIcon sx={{ mr: 1, fontSize: { xs: '1.2rem', sm: '1.5rem' } }} />
                                                        <Typography variant={isMobile ? "body2" : "body1"}>
                                                            {player.player_name}
                                                        </Typography>
                                                    </Paper>
                                                </Fade>
                                            ))}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Zoom>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Zoom in={true} timeout={900}>
                                {isHost ? (
                                    <Card 
                                        elevation={4} 
                                        sx={{ 
                                            borderRadius: { xs: 1.5, sm: 2 },
                                            transition: 'transform 0.3s ease',
                                            '&:hover': {
                                                transform: 'translateY(-5px)'
                                            }
                                        }}
                                    >
                                        <CardContent sx={{ p: { xs: 1.5, sm: 2 } }}>
                                            <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                                                <SettingsIcon color="primary" sx={{ mr: 1, fontSize: { xs: '1.5rem', sm: '2rem' } }} />
                                                <Typography variant={isMobile ? "subtitle1" : "h6"} color="primary">
                                                    تنظیمات بازی
                                                </Typography>
                                            </Box>
                                            
                                            <FormControl fullWidth sx={{ mb: { xs: 2, sm: 3 } }}>
                                                <InputLabel>سختی بازی</InputLabel>
                                                <Select
                                                    label="سختی بازی"
                                                    value={gameDifficulty}
                                                    onChange={(e) => setGameDifficulty(parseInt(e?.target?.value))}
                                                    size={isMobile ? "small" : "medium"}
                                                    startAdornment={<SpeedIcon sx={{ mr: 1 }} />}
                                                >
                                                    <MenuItem value={1}>آسان</MenuItem>
                                                    <MenuItem value={2}>معمولی</MenuItem>
                                                    <MenuItem value={3}>سخت</MenuItem>
                                                </Select>
                                            </FormControl>

                                            <FormControl fullWidth sx={{ mb: { xs: 2, sm: 3 } }}>
                                                <InputLabel>تعداد جاسوس‌ها</InputLabel>
                                                <Select
                                                    label="تعداد جاسوس‌ها"
                                                    value={settings.spy_count}
                                                    onChange={(e) => updateSettings('spy_count', parseInt(e.target.value), settings, isHost)}
                                                    size={isMobile ? "small" : "medium"}
                                                    startAdornment={<SupervisorAccountIcon sx={{ mr: 1 }} />}
                                                >
                                                    {[1, 2, 3].map(num => (
                                                        <MenuItem key={num} value={num}>{num}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <Box sx={{ mb: { xs: 3, sm: 4 } }}>
                                                <Box display="flex" alignItems="center" gap={1} mb={2}>
                                                    <TimerIcon color="primary" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }} />
                                                    <Typography color="primary" variant={isMobile ? "body2" : "body1"}>
                                                        زمان بازی (دقیقه)
                                                    </Typography>
                                                </Box>
                                                <Slider
                                                    value={settings.match_time}
                                                    onChange={(e) => handleTimeChange(e, settings, isHost)}
                                                    step={1}
                                                    marks={marks}
                                                    min={3}
                                                    max={60}
                                                    valueLabelDisplay="auto"
                                                    sx={{
                                                        '& .MuiSlider-markLabel': {
                                                            fontSize: { xs: '0.75rem', sm: '0.875rem' }
                                                        }
                                                    }}
                                                />
                                            </Box>

                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 1.5, sm: 2 } }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={startNewGame}
                                                    disabled={players?.length < 1}
                                                    startIcon={<SportsEsportsIcon sx={{ color: 'white', mx: 1 }} />}
                                                    size={isMobile ? "medium" : "large"}
                                                    sx={{ 
                                                        py: { xs: 1, sm: 1.5 },
                                                        borderRadius: { xs: 1.5, sm: 2 },
                                                        transition: 'all 0.3s ease',
                                                        '&:hover': {
                                                            transform: 'translateY(-2px)',
                                                            boxShadow: theme.shadows[8]
                                                        }
                                                    }}
                                                >
                                                    شروع بازی
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={removeRoom}
                                                    startIcon={<DeleteIcon sx={{ color: 'white', mx: 1 }} />}
                                                    size={isMobile ? "medium" : "large"}
                                                    sx={{ 
                                                        py: { xs: 1, sm: 1.5 },
                                                        borderRadius: { xs: 1.5, sm: 2 },
                                                        transition: 'all 0.3s ease',
                                                        '&:hover': {
                                                            transform: 'translateY(-2px)',
                                                            boxShadow: theme.shadows[8]
                                                        }
                                                    }}
                                                >
                                                    حذف اتاق
                                                </Button>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                ) : (
                                    <Card 
                                        elevation={4}
                                        sx={{ 
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: { xs: 1.5, sm: 2 },
                                            background: 'linear-gradient(145deg, #f8f9fa 0%, #e9ecef 100%)'
                                        }}
                                    >
                                        <CardContent sx={{ width: '100%', p: { xs: 1.5, sm: 2 } }}>
                                            <Typography 
                                                variant={isMobile ? "subtitle1" : "h6"}
                                                align="center" 
                                                color="text.secondary"
                                                sx={{ fontWeight: 500, mb: { xs: 2, sm: 3 } }}
                                            >
                                                منتظر شروع بازی توسط میزبان...
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={leaveRoom}
                                                fullWidth
                                                startIcon={<ExitToAppIcon sx={{ color: 'white', mx: 1 }} />}
                                                size={isMobile ? "medium" : "large"}
                                                sx={{ 
                                                    py: { xs: 1, sm: 1.5 },
                                                    borderRadius: { xs: 1.5, sm: 2 },
                                                    transition: 'all 0.3s ease',
                                                    '&:hover': {
                                                        transform: 'translateY(-2px)',
                                                        boxShadow: theme.shadows[8]
                                                    }
                                                }}
                                            >
                                                خروج از اتاق
                                            </Button>
                                        </CardContent>
                                    </Card>
                                )}
                            </Zoom>
                        </Grid>
                    </Grid>
                </Paper>
            </Fade>
        </Container>
    );
};

export default GameSettings;