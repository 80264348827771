/** fileName: MasterReducer.js
 * developer: majid.garavand@gmail.com
 * created: 3/17/23
 * note: Do not delete this comment to maintain copyright rules
 * */
const MasterReducer = (state = {}, action) => {
    // console.log('master reducer',action.type)
    return action.type ? {...state, [action?.type]: action.payload} : state;
}

export default MasterReducer;
