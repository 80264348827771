import { useCallback } from 'react';

export const useChatManagement = (sendMessage, ws) => {
    const sendChatMessage = useCallback((message) => {
        sendMessage({
            event: 'room-chat-message',
            ...message
        });
    }, [sendMessage]);

    const sendGlobalChatMessage = useCallback((message) => {
        sendMessage({
            event: 'global-chat-message',
            ...message
        });
    }, [sendMessage]);

    const getLastGlobalChatMessage = useCallback(() => {
        if(!ws) return;
        sendMessage({
            event: 'last-global-chat-message',
        });
    }, [ws, sendMessage]);

    return {
        sendChatMessage,
        sendGlobalChatMessage,
        getLastGlobalChatMessage
    };
}; 

export default useChatManagement;