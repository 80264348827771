import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    TextField,
    IconButton,
    Paper,
    Typography,
    Stack,
    Button,
    Grid,
    Popover
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import EmojiPicker from 'emoji-picker-react';

const RoomChatBox = ({ currentPlayerId, players, roomId, sendRoomChatMessage, chatMessages, message, setMessage }) => {
    const chatBoxRef = useRef(null);
    const emojiPickerRef = useRef(null);
    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);

    // Auto scroll to bottom when new messages arrive
    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [chatMessages]);

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (!message.trim()) return;

        sendRoomChatMessage({
            message: message.trim(),
            type: 'text'
        });

        setMessage('');
    };

    const onEmojiClick = (emojiObject) => {
        setMessage(prev => prev + emojiObject.emoji);
        setEmojiPickerOpen(false);
    };

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new MediaRecorder(stream);
            const chunks = [];

            recorder.ondataavailable = (e) => chunks.push(e.data);
            recorder.onstop = async () => {
                const audioBlob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' });
                const base64Audio = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(audioBlob);
                });

                console.log('room-base64Audio', base64Audio);
                sendRoomChatMessage({
                    message: base64Audio,
                    type: 'audio'
                });
            };

            recorder.start();
            setMediaRecorder(recorder);
            setIsRecording(true);
        } catch (err) {
            console.error('Error accessing microphone:', err);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.stop();
            setIsRecording(false);
            mediaRecorder.stream.getTracks().forEach(track => track.stop());
        }
    };


    const renderMessage = (msg) => {
        if (msg.type === 'text-message') {
            return msg.message;
        }else if(msg.type === 'image-message'){
            return <img src={msg.message} alt="message" style={{maxWidth: '200px'}} />;
        }else if(msg.type === 'audio-message'){
            return <audio src={msg.message} controls style={{maxWidth: '200px'}} />;
        }else if(msg.type === 'video-message'){
            return <video src={msg.message} controls style={{maxWidth: '200px'}} />;
        }else if(msg.type === 'file-message'){
            return <a href={msg.message} download>Download File</a>;
        }else if(msg.type === 'link-message'){
            return <a href={msg.message} target="_blank" rel="noopener noreferrer">{msg.message}</a>;
        }else if(msg.type === 'location-message'){
            return <a href={msg.message} target="_blank" rel="noopener noreferrer">{msg.message}</a>;
        }else if(msg.type === 'poll-message'){
            return <div>Poll</div>;
        }else if(msg.type === 'poll-option-message'){
            return <div>Poll Option</div>;
        }else if(msg.type === 'poll-result-message'){
            return <div>Poll Result</div>;
        }else if(msg.type === 'game-message'){
            return <div>Game Message</div>;
        }else if(msg.type === 'game-action-message'){
            return <div>Game Action Message</div>;
        }else if(msg.type === 'game-result-message'){
            return <div>Game Result Message</div>;
        }else if(msg.type === 'game-action-message'){
            return <div>Game Action Message</div>;
        }else if(msg.type === 'game-result-message'){
            return <div>Game Result Message</div>;
        }
        return msg.message;
    };

    return (
        <Paper 
            elevation={2}
            sx={{ 
                mt: 3,
                p: 2,
                borderRadius: 2,
                position: 'relative',
                flexGrow: 1
            }}
        >
            <Typography variant="h6" sx={{ mb: 2 }}>
                چت اتاق
            </Typography>
            
            <Box
                ref={chatBoxRef}
                dir={'rtl'}
                sx={{
                    height: '200px',
                    overflowY: 'auto',
                    mb: 2,
                    p: 1,
                    bgcolor: 'background.default',
                    borderRadius: 1
                }}
            >
                {chatMessages.map((msg, index) => (
                    <Stack
                    key={index}
                    direction="row"
                    spacing={1}
                    sx={{
                        mb: '1px',
                        flexDirection: msg.player_id === currentPlayerId ? 'row-reverse' : 'row',
                        bgcolor: msg.player_id === currentPlayerId ? 'primary.light' : 'grey.200',
                        color: msg.player_id === currentPlayerId ? 'white' : 'text.primary',
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            py: 1,
                            borderRadius: 1,
                            maxWidth: '80%'
                        }}
                    >
                        <strong>{msg.player_name}</strong>
                    </Typography>
                    <strong style={{ padding: '4px 2px'}}>{':'}</strong>

                    <Typography
                        variant="body2"
                        sx={{
                            py: 1,
                            borderRadius: 1,
                            maxWidth: '80%'
                        }}
                    >
                        {renderMessage(msg)}
                    </Typography>

                </Stack>
                ))}
            </Box>

            <form onSubmit={handleSendMessage}>
                <Box 
                    display="grid" 
                    gridTemplateColumns="8fr 4fr"
                    gap={1}
                    alignItems="center"
                >
                    <TextField
                        fullWidth
                        size="small"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="پیام خود را بنویسید..."
                        variant="outlined"
                    />
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <IconButton 
                            type="submit" 
                            color="primary"
                            disabled={!message.trim()}
                        >
                            <SendIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}
                            color="primary"
                        >
                            <EmojiEmotionsIcon />
                        </IconButton>
                        <IconButton
                            onClick={isRecording ? stopRecording : startRecording}
                            color={isRecording ? "error" : "primary"}
                            ref={emojiPickerRef}
                        >
                            {isRecording ? <StopIcon /> : <MicIcon />}
                        </IconButton>
                    </Box>
                </Box>
            </form>

            {/* {emojiPickerOpen && ( */}
                <Popover
                    open={emojiPickerOpen}
                    keepMounted={true}
                    onClose={() => setEmojiPickerOpen(false)}
                    anchorEl={emojiPickerRef.current}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    // sx={{
                    //     '& .MuiPopover-paper': {
                    //         position: 'fixed',
                    //         bottom: '100px',
                    //         right: '20px',
                    //     }
                    // }}
                >
                    <Paper elevation={3}>
                        <EmojiPicker onEmojiClick={onEmojiClick} lazyLoadEmojis={true} allowExpandReactions={true}/>
                    </Paper>
                </Popover>
            {/* )} */}
        </Paper>
    );
};

export default RoomChatBox;