import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import masterReducer from "./../reducer/MasterReducer"
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage';


const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        'persist/REHYDRATE',
        '_persist',
        // 'name'

    ],
}
const persistedReducer = persistReducer(persistConfig, masterReducer)
const middleware = [thunk];


export const store = createStore(persistedReducer, applyMiddleware(...middleware));
export const persist = persistStore(store)


