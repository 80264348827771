import React, { useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Paper,
    Container,
    Grid,
    Zoom,
    Fade,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LoginIcon from '@mui/icons-material/Login';

const LoginForm = ({
    playerName,
    setPlayerName,
    activeRooms,
    roomError,
    createRoom,
    settings,
    joinRoom,
    setRoomId,
    roomId
}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [newRoomName, setNewRoomName] = useState('');

    const handleCreateRoom = () => {
        createRoom(newRoomName, playerName, settings);
        setOpenDialog(false);
        setNewRoomName('');
    };

    return (
        <Container maxWidth="sm" sx={{ mb: 2 }}>
            <Dialog 
                open={openDialog} 
                onClose={() => setOpenDialog(false)}
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                        minWidth: { xs: '90%', sm: '400px' }
                    }
                }}
            >
                <DialogTitle sx={{ textAlign: 'center' }}>ایجاد اتاق جدید</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="نام اتاق"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={newRoomName}
                        onChange={(e) => setNewRoomName(e.target.value)}
                        sx={{ mt: 2 }}
                    />
                </DialogContent>
                <DialogActions sx={{ p: 2, justifyContent: 'center' }}>
                    <Button 
                        onClick={() => setOpenDialog(false)} 
                        variant="outlined"
                        sx={{ minWidth: 100 }}
                    >
                        انصراف
                    </Button>
                    <Button 
                        onClick={handleCreateRoom}
                        variant="contained"
                        disabled={!newRoomName.trim()}
                        sx={{ minWidth: 100 }}
                    >
                        ایجاد
                    </Button>
                </DialogActions>
            </Dialog>

            <Fade in={true} timeout={800}>
                <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                    <Box 
                        component="form" 
                        onSubmit={joinRoom}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 3
                        }}
                    >
                        <Zoom in={true} timeout={500}>
                            <TextField
                                fullWidth
                                label="نام شما"
                                value={playerName}
                                onChange={(e) => setPlayerName(e.target.value)}
                                required
                                variant="outlined"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: 'primary.main',
                                        },
                                    },
                                }}
                            />
                        </Zoom>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Zoom in={true} timeout={600}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setOpenDialog(true)}
                                        fullWidth
                                        type="button"
                                        startIcon={<AddCircleOutlineIcon sx={{ color: 'white',mx: 1 }} />}
                                        sx={{
                                            py: 1.5,
                                            borderRadius: 2,
                                            boxShadow: 2,
                                            transition: 'transform 0.2s ease-in-out',
                                            '&:hover': {
                                                transform: 'scale(1.02)',
                                            }
                                        }}
                                    >
                                        ایجاد اتاق جدید
                                    </Button>
                                </Zoom>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Zoom in={true} timeout={700}>
                                    <FormControl fullWidth>
                                        <InputLabel>انتخاب اتاق</InputLabel>
                                        <Select
                                            value={roomId}
                                            onChange={(e) => setRoomId(e.target.value)}
                                            label="انتخاب اتاق"
                                            sx={{
                                                borderRadius: 1,
                                                '&:hover': {
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'primary.main',
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem key={0} value={'0'}>
                                                {'انتخاب اتاق'}
                                            </MenuItem>
                                            {activeRooms.map((room) => (
                                                <MenuItem key={room?.id} value={room?.id}>
                                                    {room?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Zoom>
                            </Grid>
                        </Grid>

                        {roomError && (
                            <Fade in={true}>
                                <Typography 
                                    color="error" 
                                    variant="body2"
                                    sx={{ 
                                        textAlign: 'center',
                                        bgcolor: 'error.light',
                                        p: 1,
                                        borderRadius: 1
                                    }}
                                >
                                    {roomError}
                                </Typography>
                            </Fade>
                        )}

                        <Zoom in={true} timeout={800}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={joinRoom}
                                fullWidth
                                type="button"
                                disabled={!roomId || !activeRooms.find(room => room.id === roomId)}
                                startIcon={<LoginIcon />}
                                sx={{
                                    py: 1.5,
                                    borderRadius: 2,
                                    boxShadow: 3,
                                    transition: 'all 0.3s ease',
                                    '&:not(:disabled):hover': {
                                        transform: 'translateY(-2px)',
                                        boxShadow: 4,
                                    },
                                    '&:disabled': {
                                        bgcolor: 'action.disabledBackground',
                                    }
                                }}
                            >
                                پیوستن به اتاق
                            </Button>
                        </Zoom>
                    </Box>
                </Paper>
            </Fade>
        </Container>
    );
};

export default LoginForm;