import React, { useState, useRef } from 'react';
import {
    Box,
    TextField,
    IconButton,
    Paper,
    Typography,
    Stack,
    Popover
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import EmojiPicker from 'emoji-picker-react';

const GlobalChatBox = ({ currentPlayerId, players, sendGlobalChatMessage, globalChatMessages }) => {
    const [message, setMessage] = useState('');
    const chatBoxRef = useRef(null);
    const emojiPickerRef = useRef(null);
    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

    const handleSendGlobalChatMessage = (e) => {
        e.preventDefault();
        if (!message.trim()) return;

        sendGlobalChatMessage({
                message: message.trim(),
                type: 'text'
        });

        setMessage('');
    };

    const onEmojiClick = (emojiObject) => {
        setMessage(prev => prev + emojiObject.emoji);
        setEmojiPickerOpen(false);
    };

    const getPlayerName = (playerId) => {
        const player = players.find(p => p.player_id === playerId);
        return player ? player.player_name : playerId? playerId: 'Unknown';
    };

    const renderMessage = (msg) => {
        if (msg.type === 'text-message') {
            return msg.message;
        }else if(msg.type === 'image-message'){
            return <img src={msg.message} alt="message" />;
        }else if(msg.type === 'audio-message'){
            return <audio src={msg.message} controls />;
        }else if(msg.type === 'video-message'){
            return <video src={msg.message} controls />;
        }else if(msg.type === 'file-message'){
            return <a href={msg.message} download>Download File</a>;
        }else if(msg.type === 'link-message'){
            return <a href={msg.message} target="_blank" rel="noopener noreferrer">{msg.message}</a>;
        }else if(msg.type === 'location-message'){
            return <a href={msg.message} target="_blank" rel="noopener noreferrer">{msg.message}</a>;
        }else if(msg.type === 'poll-message'){
            return <div>Poll</div>;
        }else if(msg.type === 'poll-option-message'){
            return <div>Poll Option</div>;
        }else if(msg.type === 'poll-result-message'){
            return <div>Poll Result</div>;
        }else if(msg.type === 'game-message'){
            return <div>Game Message</div>;
        }else if(msg.type === 'game-action-message'){
            return <div>Game Action Message</div>;
        }else if(msg.type === 'game-result-message'){
            return <div>Game Result Message</div>;
        }else if(msg.type === 'game-action-message'){
            return <div>Game Action Message</div>;
        }else if(msg.type === 'game-result-message'){
            return <div>Game Result Message</div>;
        }
        return msg.message;
    };

    return (
        <Paper 
            elevation={3}
            sx={{
                p: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1
            }}
        >
            <Typography variant="h6" gutterBottom>
                چت گروهی
            </Typography>

            <Box
                ref={chatBoxRef}
                dir="rtl"
                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    mb: 2,
                    p: 1
                }}
            >
                {globalChatMessages.map((msg, index) => (
                    <Stack
                        key={index}
                        direction="row"
                        spacing={1}
                        sx={{
                            mb: '1px',
                            flexDirection: msg.player_id === currentPlayerId ? 'row-reverse' : 'row',
                            bgcolor: msg.player_id === currentPlayerId ? 'primary.light' : 'grey.200',
                            color: msg.player_id === currentPlayerId ? 'white' : 'text.primary',
                        }}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                py: 1,
                                borderRadius: 1,
                                maxWidth: '80%'
                            }}
                        >
                            <strong>{getPlayerName(msg.player_name)}</strong>
                        </Typography>
                        <strong style={{ padding: '4px 2px'}}>{':'}</strong>

                        <Typography
                            variant="body2"
                            sx={{
                                py: 1,
                                borderRadius: 1,
                                maxWidth: '80%'
                            }}
                        >
                            {renderMessage(msg)}
                        </Typography>

                    </Stack>
                ))}
            </Box>

            <form onSubmit={handleSendGlobalChatMessage}>
                <Box 
                    display="grid" 
                    gridTemplateColumns="8fr 4fr"
                    gap={1}
                    alignItems="center"
                >
                    <TextField
                        fullWidth
                        size="small"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="پیام خود را بنویسید..."
                        variant="outlined"
                    />
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <IconButton 
                            type="submit" 
                            color="primary"
                            disabled={!message.trim()}
                        >
                            <SendIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}
                            color="primary"
                            ref={emojiPickerRef}
                        >
                            <EmojiEmotionsIcon />
                        </IconButton>
                    </Box>
                </Box>
            </form>

            <Popover
                open={emojiPickerOpen}
                keepMounted={true}
                onClose={() => setEmojiPickerOpen(false)}
                anchorEl={emojiPickerRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Paper elevation={3}>
                    <EmojiPicker onEmojiClick={onEmojiClick} lazyLoadEmojis={true} allowExpandReactions={true}/>
                </Paper>
            </Popover>
        </Paper>
    );
};

export default GlobalChatBox;