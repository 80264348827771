import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux";
import {persist, store} from "./redux/store/index.store";
import rtlPlugin from 'stylis-plugin-rtl';
import {CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache';
import {prefixer} from 'stylis';
import {ThemeProvider} from '@mui/material/styles';
import App from "./App";
import {theme} from "./Theme";

// Create rtl cache
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});


let elm = document.getElementById('root');
elm.setAttribute("style", 'min-height:100vh; display:flex; flex-direction: column;');
const root = createRoot(elm);

root.render(
    <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                    <persistGate persist={persist}>
                        {/*<WordGuess/>*/}
                        <App/>
                    </persistGate>
            </Provider>
        </ThemeProvider>
    </CacheProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
